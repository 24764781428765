import React, { useState } from "react";
import styled from "styled-components";
import { Link, StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import { IoIosDownload, IoIosCall, IoIosMail, IoMdOpen } from "react-icons/io";

import { useModal } from "../../components/useModal";

import { Container, Wrapper, Row, Box } from "../../components/util";
import { Accordion, Modal, Breadcrumb } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

const PartnerResourceWrapper = styled.div`
  width: 100%;
  padding: 100px 1rem;
  background-color: rgba(255, 255, 255, 0.6);
`;

const PartnerClientSetup = (props) => {
  var [calendlySource, setCalendlySource] = useState("");
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const handleModal = function(e) {
    toggleModal(true);
    if (e.target.name === "support") {
      setCalendlySource("payroll-specialist-call");
    } else if (e.target.name === "training") {
      setCalendlySource("training");
    }
  };

  return (
    <Layout>
      <Seo
        title="Get Clients Set Up | Partner Resources | FlexiTime Partner"
        pathname={props.location.pathname}
      />

      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        parentPage="Partner Resources"
        parentPageLink="/partner-resources"
        currentPage="Get Clients Setup"
      />
      <Container>
        <StaticQuery
          query={graphql`
            query {
              desktop: file(
                relativePath: {
                  eq: "partner-toolkit/Partner Resource Pic1.png"
                }
              ) {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={(data) => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
              <BackgroundImage
                Tag="section"
                fluid={imageData}
                backgroundColor={`#fff`}
              >
                <PartnerResourceWrapper className="-textCenter">
                  <div>
                    <h1>Get Clients Set Up</h1>
                    <h4>Guides and processes to set up your clients</h4>
                  </div>
                </PartnerResourceWrapper>
              </BackgroundImage>
            );
          }}
        />
      </Container>
      <Container>
        <Wrapper maxWidth={800}>
          <Box stackGap={30}>
            <h4 className="h2">Getting Started</h4>
            <Box stackGap={20}>
              <Accordion number="1" title="Training & Certification">
                <Box stackGap={30}>
                  <h4>
                    <a
                      className="link-floating"
                      href="#!"
                      name="training"
                      onClick={handleModal}
                    >
                      Book a Training session
                    </a>
                  </h4>
                  <h4>
                    Email{" "}
                    <a href="mailto:partners@flexitime.works">
                      partners@flexitime.works
                    </a>{" "}
                    with the subject <b>“Certified”</b> to get started with our
                    payroll certification
                  </h4>
                </Box>
              </Accordion>
              <Accordion number="2" title="Setup ">
                <Box stackGap={30}>
                  <h4>
                    <a
                      className="link-floating"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.payhero.co.nz/hc/en-us/articles/360002749075-Getting-started-with-PayHero-in-5-easy-steps"
                    >
                      5 Steps to get started <IoMdOpen css={{ top: "3px" }} />
                    </a>
                  </h4>
                  <h4>
                    <a
                      className="link-floating"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.payhero.co.nz/hc/en-us"
                    >
                      Support Centre <IoMdOpen css={{ top: "3px" }} />
                    </a>
                  </h4>
                  <h4>
                    <Link
                      className="link-floating"
                      to="https://www.payhero.co.nz/tutorials"
                    >
                      Watch PayHero Tutorial Videos
                    </Link>
                  </h4>
                  <h4>
                    Email Support -{" "}
                    <a href="mailto:support@flexitime.works">
                      support@flexitime.works
                    </a>
                  </h4>
                  <h4>
                    <a
                      className="link-floating"
                      name="support"
                      onClick={handleModal}
                      href="#!"
                    >
                      Schedule a Support Call
                    </a>
                  </h4>
                </Box>
              </Accordion>
              <Accordion number="3" title="Payroll Conversions">
                <Box stackGap={30}>
                  {/* <h4>
                    <a
                      href="/media/Xero_PayrollConversion.pdf"
                      className="link-floating"
                      download
                    >
                      Xero Payroll <span className="-fontNormal">(52KB)</span>{" "}
                      <IoIosDownload css={{ top: "1px", marginLeft: "8px" }} />
                    </a>
                  </h4> */}
                  <h4>
                    <a
                      className="link-floating"
                      href="https://support.payhero.co.nz/hc/en-us/articles/360002666496-Employee-Import"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Employee Import <IoMdOpen css={{ top: "3px" }} />
                    </a>
                  </h4>
                  <h4>
                    <a
                      className="link-floating"
                      href="https://support.payhero.co.nz/hc/en-us/articles/360002750355-Pay-History-Import"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pay History Import <IoMdOpen css={{ top: "3px" }} />
                    </a>
                  </h4>
                  <h4>
                    <a
                      className="link-floating"
                      href="https://www.payhero.co.nz/smartly-payroll"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Converting Leave Balances from SmartPayroll{" "}
                      <IoMdOpen css={{ top: "3px" }} />
                    </a>
                  </h4>
                </Box>
              </Accordion>
            </Box>
          </Box>
          <Box stackGap={30}>
            <h4 className="h2">Reporting</h4>
            <Box stackGap={30}>
              <h4>
                <a
                  className="link-floating"
                  href="https://support.payhero.co.nz/hc/en-us/articles/360002749095-Insights-Reports"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Insights Reports <IoMdOpen css={{ top: "3px" }} />
                </a>
              </h4>
              <h4>
                <a
                  className="link-floating"
                  href="https://support.payhero.co.nz/hc/en-us/articles/360002749495-Insights-Glossary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Insights Glossary <IoMdOpen css={{ top: "3px" }} />
                </a>
              </h4>
              <h4>
                <a
                  className="link-floating"
                  href="https://support.payhero.co.nz/hc/en-us/articles/360002750755-End-of-Year-Annual-Leave-Reporting"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  End of Year Annual Leave Reporting{" "}
                  <IoMdOpen css={{ top: "3px" }} />
                </a>
              </h4>
            </Box>
          </Box>
          <Box stackGap={30}>
            <div>
              <h4 className="h2">Helpful links</h4>
              <h4>Other links you might find useful:</h4>
            </div>
            <Box stackGap={30}>
              <h4>
                <a
                  className="link-floating"
                  href="https://support.payhero.co.nz/hc/en-us/sections/360000731875-Release-Notes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PayHero Release Notes <IoMdOpen css={{ top: "3px" }} />
                </a>
              </h4>
              <h4>
                <a
                  className="link-floating"
                  href="https://support.droppah.com/hc/en-us/sections/360000492275-Release-Notes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Droppah Release Notes <IoMdOpen css={{ top: "3px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="/media/partners/Managing_Client_Subscriptions_FlexiTimePartner.pdf"
                  className="link-floating"
                  download
                >
                  Managing Client Subscriptions{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "8px" }} />
                </a>
              </h4>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container className="-textCenter secondary">
        <Wrapper stackGap={80}>
          <h2>Need help? Get in touch</h2>
          <Row css={{ maxWidth: "900px" }} className="-center">
            <Box className="-center" size={50} stackGap={30}>
              <Image
                filename="PeteAshby_Circle.png"
                alt="Pete Ashby | FlexiTime Partner Contact"
                width={80}
                centerImage
              />
              <h4>
                <span className="-fontBold">Pete Ashby</span>
                <br />
                Head of Discovery
              </h4>
              <h4 className="-fontBold">
                <a
                  href=" https://calendly.com/flexitime-partners/partner-manager"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating -center"
                >
                  <IoIosCall css={{ position: "relative", top: "3px" }} /> Book
                  a Call
                </a>
              </h4>
              <h4 className="-fontBold">
                <a
                  className="link-floating -center"
                  href="mailto:pete@flexitime.works"
                >
                  <IoIosMail
                    css={{
                      position: "relative",
                      top: "3px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  pete@flexitime.works
                </a>
              </h4>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL={`https://calendly.com/flexitime-partners/${calendlySource}`}
        embedTitle="Book a Support Call | PayHero Support"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default PartnerClientSetup;
